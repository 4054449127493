<template>
	<CRow v-if="user">
		<CCol
			sm="12"
			md="12"
			lg="12"
			xl="12"
			xxl="12"
		>
			<CCard>
				<CCardHeader>
					Anagrafica
				</CCardHeader>
				<CCardBody>
					<CRow>
						<CCol sm="6">
							<h6>Nome</h6>
							<p>{{ user.firstName}}</p>
						</CCol>
						<CCol sm="6">
							<h6>Cognome</h6>
							<p>{{ user.lastName}}</p>
						</CCol>
						<CCol sm="6">
							<h6>Email</h6>
							<p>{{ user.email}}</p>
						</CCol>
					</CRow>
				</CCardBody>
			</CCard>
		</CCol>
		<CCol
			sm="12"
			md="12"
			lg="12"
			xl="12"
			xxl="12"
		>
			<h2 class="mb-sm-4 mb-0">METRICHE UTENTE</h2>
			<CRow class="date-pickers">
				<CCol
					col="12"
					sm="6"
					md="5"
				>
					<h6>Dal</h6>
					<date-picker
						v-model="fromDate"
						format="DD-MM-YYYY"
						input-class="form-control"
					></date-picker>
				</CCol>
				<CCol
					col="12"
					sm="6"
					md="5"
				>
					<h6>Al</h6>
					<date-picker
						v-model="toDate"
						format="DD-MM-YYYY"
						:disabled-date="checkData"
						input-class="form-control"
					></date-picker>
				</CCol>
				<CCol
					col="12"
					sm="12"
					md="2"
					class="column-picker-button"
				>
					<CButton
						color="sdAzure"
						class="px-3"
						@click="loadStatistics()"
						:disabled="fromDate === null || toDate === null"
					>Filtra <i class="fas fa-search"></i>
					</CButton>
				</CCol>
			</CRow>
			<CRow v-if="statistics && statistics.totalTime !== null">
				<CCol
					xs="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
					xxl="3"
				>
					<CustomWidget
						:value="Math.floor(statistics.maxBpm).toString() || '0'"
						title="Frequenza Max"
						color="sdRed"
					></CustomWidget>
				</CCol>
				<CCol
					col="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
					xxl="3"
				>
					<CustomWidget
						:value="Math.floor(statistics.minBpm).toString() || '0'"
						title="Frequenza Min"
						color="sdYellow"
					></CustomWidget>
				</CCol>
				<CCol
					xs="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
					xxl="3"
				>
					<CustomWidget
						:value="Math.floor(statistics.avgBpm).toString() || '0'"
						title="Frequenza Media"
						color="sdAzure"
					></CustomWidget>
				</CCol>
				<CCol
					col="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
					xxl="3"
				>
					<CustomWidget
						:value="intToTimeLeft(statistics.totalTime) || '0'"
						title="Durata totale"
						color="sdRed"
					></CustomWidget>
				</CCol>
				<CCol
					xs="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
					xxl="3"
				>
					<CustomWidget
						:value="Math.floor(statistics.totalSessions).toString() || '0'"
						title="Sessioni totali"
						color="sdGray"
					></CustomWidget>
				</CCol>
			</CRow>
			<template v-else>
				<CAlert color="sdAzure">
					Metriche non disponibili
				</CAlert>
			</template>
		</CCol>
	</CRow>
</template>

<script>

import { isValidUUID, intToTimeLeft } from "../../utilities/utilities";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/it';
import 'vue2-datepicker/index.css';
import { DateTime } from "luxon";

export default {
	name: 'UtenteDettaglio',
	created() {
		const toDate = DateTime.local();
		const fromDate = DateTime.local().minus({ days: 7 });
		this.fromDate = fromDate.toJSDate();
		this.toDate = toDate.toJSDate();
		if (this.userId) {
			this.$store.dispatch("getUserById", this.userId).then(user => {
				this.user = user;
				this.loadStatistics();
			});
		}
	},
	components: { DatePicker },
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	data() {
		return {
			user: undefined,
			statistics: undefined,
			fromDate: null,
			toDate: null,
			now: new Date(),
		}
	},
	computed: {
		userId() {
			const routeId = this.$route.params.id;
			return isValidUUID(routeId) ? routeId : undefined;
		},
	},
	methods: {
		isValidUUID,
		intToTimeLeft,
		checkData(value) {
			const valueTime = value.getTime();
			const nowTime = this.now.getTime();
			return valueTime <= this.fromDate.getTime() || valueTime >= nowTime;
		},
		loadStatistics() {
			this.$store.commit('loadState', true);
			this.$store.dispatch("getStatisticsByUserId", { id: this.userId, fromDate: new Date(this.fromDate).toISOString(), toDate: new Date(this.toDate).toISOString() }).then(statistics => {
				this.statistics = statistics;
				this.$store.commit('loadState', false);
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.date-pickers {
	::v-deep {
		.mx-datepicker {
			margin-bottom: 2.3rem;
		}
	}
}
@media screen and (max-width: 767px) {
	.column-picker-button {
		text-align: center;
		button {
			width: 100%;
			margin-bottom: 1.4rem;
		}
	}
}

@media screen and (min-width: 768px) {
	.column-picker-button {
		text-align: right;
		button {
			margin-top: 1.4rem;
		}
	}
}
</style>